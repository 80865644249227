import axios from 'axios';

import { LICENSING_BASE } from '../constants-base';
import { b64ToZip } from './b64-to-zip';

const licensingService = axios.create({
    baseURL: `${LICENSING_BASE}`,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    validateStatus(status) {
        return status >= 200 && status < 600; // default
    },
});

/* eslint-disable valid-jsdoc */

/**
 * Executes a POST request to the Sans Licensing service, with an optional payload.
 * On a successful response, `response.data.license` is returned, for easier use
 * with client/API integrations. If `response.data` is the preferred return,
 * set the `returnData` parameter
 * @param {string} licenseIntegration - the licensing service to interact with
 * @param {import("../../../types/types").LabData} labData - vital data about the lab this request is being made for
 * @param {Object} requestConfig
 * @param {Object} [requestConfig.payload] - The data to send with the request
 * @param {string} [requestConfig.responseType] - If not application/json, the expected response MIME type
 * @param {boolean} [requestConfig.returnData]
 * - When true, returns the entire response.data object rather than `response.data.license`
 */
export const fetchLicenseDataAsPost = async (
    licenseIntegration, labData, { payload = {}, responseType = 'json', returnData = false } = {}) => {
    try {
        /**
         * The raw payload for the request. It'll usually be a JSON object.
         * If there's no payload for the request, default to an empty object
         * since we'll be appending metadata to it.
         */
        const rawPayload = payload;

        const axiosRequestConfig = { responseType };
        const runId = 'RUN_ID';
        const courseInstance = labData.eventProductId;
        const courseMetadata = {
            /**
             * The run_id when it exists. Current fallbacks:
             * event_product_id > name
             */
            label: runId,
            /**
             * The modality, course catalog name, and run id of a lab's course
             */
            name: `${labData.modality} ${labData.courseCatalogName} (${courseInstance})`,
            // TODO: Document this field
            key: courseInstance,
            course: labData.courseCatalogName,
        };

        let fullPayload = {
            metadata: { ...courseMetadata },
        };

        if (Object.values(rawPayload).length > 0) {
            fullPayload = {
                ...rawPayload,
                ...fullPayload,
            };
        }
        const response = await licensingService.post(
            licenseIntegration, fullPayload, axiosRequestConfig);

        if (returnData) {
            return response.data;
        }

        return response.data.license;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e.message);
        throw e;
    }
};

/**
 * Retrieve an Arsenal license from the SANS licensing service
 * @param {import('../../../types/types').LabData} labData
 * @returns {Promise<import('../../../types/types').LicenseServiceResponse>}
 */
export const getArsenalLicense = async (labData) => {
    try {
        const license = await fetchLicenseDataAsPost('arsenal', labData);

        return { license: license.license_code };
    } catch (e) {
        return { error: e.message };
    }
};

/**
 * Retrieve a Cellebrite license file from the Sans licensing svc.
 * @param {import('../../../types/types').LabData} labData
 * @param {Object} options
 * @param {string} options.c2v - The c2v string
 * @returns {Promise<import('../../../types/types').LicenseServiceResponse>}
 */
export const getCellebriteLicense = async (labData, { c2v }) => {
    try {
        const licenseb64 = await fetchLicenseDataAsPost(
            'cellebrite', labData, { payload: { c2v }}); // decode => blobify
        const zipBlob = await b64ToZip(licenseb64, 'license');

        return { license: zipBlob };
    } catch (e) {
        return { error: e.message };
    }
};

/**
 * Retrieve a Corellium license from the Sans licensing service
 * @param {import('../../../types/types').LabData} labData
 * @returns {Promise<import('../../../types/types').LicenseServiceResponse>}
 */
export const getCorelliumLicense = async (labData) => {
    try {
        const imageExpiration = new Date();
        const userExpiration = new Date();

        if (labData.modality === 'OnDemand') {
            imageExpiration.setMonth(imageExpiration.getMonth() + 4);
            userExpiration.setMonth(userExpiration.getMonth() + 4);
        } else {
            imageExpiration.setMonth(imageExpiration.getMonth() + 1);
            userExpiration.setDate(userExpiration.getDate() + 14);
        }

        // 1 month live, 4 months OD
        const payload = {
            imageRemoval: imageExpiration.toISOString(),
            projectRemoval: userExpiration.toISOString(),
        };
        const license = await fetchLicenseDataAsPost('corellium', labData, { returnData: true, payload });

        return { license };
    } catch (e) {
        return { error: e.message };
    }
};

/**
 *
 * @param {import('../../../types/types').LabData} labData
 * @returns {Promise<import('../../../types/types').LicenseServiceResponse>}
 */
export const getShodanLicense = async (labData) => {
    try {
        const license = await fetchLicenseDataAsPost('shodan', labData);

        return { license: license.license_code };
    } catch (e) {
        return { error: e.message };
    }
};

/**
 * Retrieve an Idapro license from the SANS licensing service
 * @param {string} studentSelection - represents the student's platform preference
 * @param {import('../../../types/types').LabData} labData
 * @returns {Promise<import('../../../types/types').LicenseServiceResponse>}
 */
export const getIDAProLicense = async (studentSelection, labData) => {
    try {
        const raw = await fetchLicenseDataAsPost(
            'idapro', labData, { returnData: true, payload: { platform: studentSelection }});

        if (typeof raw.created_at !== 'undefined') {
            return { license: raw.created_at };
        }
    } catch (e) {
        return { error: e.message };
    }

    return { error: 'Failed to request new license' };
};

export const getXSOARLicense = async (labData) => {
    try {
        const license = await fetchLicenseDataAsPost('xsoar', labData, {
            returnData: true,
        });

        return { license };
    } catch (e) {
        return { error: e.message };
    }
};

export const getMaltegoLicense = async (labData) => {
    try {
        const license = await fetchLicenseDataAsPost('maltego', labData);

        return { license: license.license_code };
    } catch (e) {
        return { error: e.message };
    }
};

export const getFlashpointLicense = async (labData) => {
    try {
        const license = await fetchLicenseDataAsPost('flashpoint', labData, {
            returnData: true,
        });

        if (!license.success) {
            throw new Error('An error has occured.');
        }

        return { license: true };
    } catch (e) {
        return { error: e.message };
    }
};


export const getChainalysisLicense = async (labData) => {
    try {
        const licenseResponse = await fetchLicenseDataAsPost('chainalysis', labData, {
            returnData: true,
        });

        return { licenseData: licenseResponse.license.reactor_credentials };
    } catch (e) {
        return { error: e.message };
    }
};

export const requestCloudEnvironmentByProvider = async (provider, course, labData) => {
    try {
        await fetchLicenseDataAsPost(`course/${course}`, labData, { payload: { provider }});
        return true;
    } catch (e) {
        return { error: e.message };
    }
};
